import React from 'react'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Accordion from '../components/Accordion'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import FactBox from '../components/FactBox'
import HeaderWithImage from '../components/HeaderWithImage'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import DetailTemplate from '../components/DetailTemplate'

function Detail({pageContext}) {
  const shortcodes = { Accordion, FactBox, Carousel, Slide, Card, CardContainer, ShowMore, HeaderWithImage }
  const { detail } = pageContext

  return (
    <div className="Detail">
      <DetailTemplate detailTitle={detail.frontmatter.detailTitle} topicTitle={detail.frontmatter.topicTitle} sectionTitle={detail.frontmatter.sectionTitle} path={detail.slug}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {detail.body}
          </MDXRenderer>
        </MDXProvider>
      </DetailTemplate>
    </div>
  )
}

export default Detail;