import React from 'react'
import Ankle from './Ankle'
import Footer from './Footer'
import SectionHeader from './SectionHeader'
import BreadCrumbs from './BreadCrumbs'
import './DetailTemplate.scss'

const DetailTemplate = ({detailTitle, topicTitle, sectionTitle, path, image, children}) => {
  return (
    <div className='DetailTemplate'>
      <SectionHeader >
      </SectionHeader>
      <BreadCrumbs detailTitle={detailTitle} topicTitle={topicTitle} sectionTitle={sectionTitle} path={path} />
      <div className="detail-header">
        <img src={`/assets/aloevera.jpeg`} alt={detailTitle}/>
        <div className="headers">
          <h1>{detailTitle}</h1>
        </div>
      </div>
      <div className="crib">
        {children}
      </div>
      <Ankle>
      </Ankle>
      <Footer>
      </Footer>
    </div>
  )
}

export default DetailTemplate